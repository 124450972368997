<template>
    <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm mt-3"
    >
        <!-- Describa la situación -->
        <el-form-item
            id="description"
            label="Describa la situación a reportar:"
            class="w-100"
            prop="description"
        >
            <el-input
                type="textarea"
                :rows="3"
                placeholder="Describa la situación"
                v-model="ruleForm.description"
                maxlength="800"
                show-word-limit
            ></el-input>
        </el-form-item>
        <!-- Lugar donde sucedió -->
        <el-form-item
            id="place"
            label="Lugar donde sucedió:"
            class="w-100"
            prop="place"
        >
            <el-select
                v-model="ruleForm.place"
                class="w-100"
                @change="validatePlaceCondition"
                placeholder="Selecciona el lugar de incidente"
                filterable
            >
                <el-option
                    v-for="place in places"
                    :key="place.id"
                    :label="place.name"
                    :value="place.id"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <!-- Local donde sucedió -->
        <el-form-item
            id="local"
            v-if="this.showLocal"
            label="Seleccione el local u oficina:"
            class="w-100"
            prop="local"
        >
            <el-select
                v-model="ruleForm.local"
                class="w-100"
                placeholder="Selecciona el local u oficina"
                filterable
            >
                <el-option
                    v-for="local in placeDetail.locals"
                    :key="local.id"
                    :label="local.name"
                    :value="local.id"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <!-- ¿Cómo identificó el problema? -->
        <el-form-item
            label="¿Cómo identificó el comportamiento?"
            class="w-100"
            prop="behavior"
            id="behavior"
        >
            <el-select
                v-model="ruleForm.behavior"
                class="w-100"
                placeholder="Seleccione una opción"
                filterable
            >
                <el-option
                    v-for="behavior in behaviors"
                    :key="behavior.id"
                    :label="behavior.name"
                    :value="behavior.id"
                >
                </el-option>
            </el-select>
        </el-form-item>

        <el-form-item
            label="¿En qué fecha pasó aproximadamente?"
            class="w-100"
            prop="date_of_incident"
        >
            <el-date-picker
                v-model="ruleForm.date_of_incident"
                type="date"
                placeholder="Seleccione un día"
            >
            </el-date-picker>
        </el-form-item>
        <!-- Se ha informado a alguien antes? -->
        <el-form-item
            label="¿Sabe de alguien que haya intentado ocultar la situación descrita?"
            class="w-100"
        >
            <el-radio v-model="ruleForm.have_accomplice" label="0">No</el-radio>
            <el-radio v-model="ruleForm.have_accomplice" label="1">Si</el-radio>
        </el-form-item>
        <!-- Quien ha sido complice -->
        <el-form-item
            v-show="ruleForm.have_accomplice === '1'"
            label="¿Quién?"
            class="w-100"
            prop="accomplices_list"
            id="accomplices_list"
        >
            <el-input
                placeholder="¿Quiénes?"
                v-model="ruleForm.accomplices_list"
            ></el-input>
        </el-form-item>
        <!-- ¿Ha informado del problemas antes? -->
        <el-form-item
            label="¿Ha informado previamente esta situación en alguna instancia?"
            class="w-100"
        >
            <el-radio v-model="ruleForm.have_situation_reported" label="0"
                >No</el-radio
            >
            <el-radio v-model="ruleForm.have_situation_reported" label="1"
                >Si</el-radio
            >
        </el-form-item>
        <!-- A quién informó -->
        <el-form-item
            v-show="ruleForm.have_situation_reported === '1'"
            label="¿Quién?"
            class="w-100"
            prop="reported_person"
            id="reported_person"
        >
            <el-input
                placeholder="¿Con quién hablaste de este problema?"
                v-model="ruleForm.reported_person"
            ></el-input>
        </el-form-item>
        <!-- Identificar involucrados -->
        <el-form-item
            label="Identifique a las personas comprometidas en el comportamiento denunciado"
            class="w-100"
            prop="people_involved"
            id="people_involved"
        >
            <el-input
                placeholder="Si es posible, cuéntanos los nombres de quienes estuvieron involucrados"
                v-model="ruleForm.people_involved"
            ></el-input>
        </el-form-item>
        <!-- ¿Hubo testigos? -->
        <el-form-item label="¿Hubo testigos?" class="w-100">
            <el-radio v-model="ruleForm.have_witnesses" label="0">No</el-radio>
            <el-radio v-model="ruleForm.have_witnesses" label="1">Si</el-radio>
        </el-form-item>
        <!-- Indicar testigos -->
        <el-form-item
            v-show="ruleForm.have_witnesses === '1'"
            label="¿Puedes identificar a los testigos?"
            class="w-100"
            prop="witnesses"
            id="witnesses"
        >
            <el-input
                placeholder="Si es posible, cuéntanos los nombres de quienes vieron lo que pasó"
                v-model="ruleForm.witnesses"
            ></el-input>
        </el-form-item>
        <!-- Veces que ha sucedido antes -->
        <el-form-item
            label="Veces que ha sucedido esto antes: "
            class="w-100"
            prop="times"
            id="times"
        >
            <el-select
                v-model="ruleForm.times"
                class="w-100"
                placeholder="Seleccione una opción"
                filterable
            >
                <el-option key="times1" label="Una sola vez" value="1" />
                <el-option key="times2" label="Un par de veces" value="2" />
                <el-option key="times3" label="Una vez a la semana" value="3" />
                <el-option
                    key="times4"
                    label="Mas de una vez a la semana"
                    value="4"
                />
                <el-option key="times5" label="Todos los días" value="5" />
            </el-select>
        </el-form-item>
        <hr />
        <el-row type="flex" justify="space-between">
            <el-button type="info" plain @click="next(lastStep)"
                >Volver</el-button
            >
            <el-button
                v-if="!waiting"
                type="primary"
                @click.prevent="submitForm('ruleForm')"
                >Siguiente</el-button
            >
            <el-button v-if="waiting" type="primary" :loading="true"
                >Guardando...</el-button
            >
        </el-row>
    </el-form>
</template>

<script>
import moment, { now } from "moment";
export default {
    props: [
        "next",
        "setId",
        "setStepData",
        "complaint_id",
        "lastStep",
        "places",
        "locals",
        "behaviors",
        "oldData",
        "complaint",
    ],
    data() {
        const checkText = (rule, value, callback) => {
            switch (rule.field) {
                case "accomplices_list":
                    if (this.ruleForm.have_accomplice === "1" && value === "") {
                        return callback(
                            new Error("Indique quienes fueron los cómplices")
                        );
                    }
                    return callback();
                case "reported_person":
                    if (
                        this.ruleForm.have_situation_reported === "1" &&
                        value === ""
                    ) {
                        return callback(
                            new Error("Indique a qué área o personas reportó")
                        );
                    }
                    return callback();
                case "witnesses":
                    if (this.ruleForm.have_witnesses === "1" && value === "") {
                        return callback(
                            new Error("Indique los nombres de los testigos")
                        );
                    }
                    return callback();
                default:
                    return callback();
            }
        };

        const checkLocal = (rule, value, callback) => {
            if (this.placeDetail.have_list && value === "") {
                return callback(
                    new Error("Debe seleccionar un local u oficina")
                );
            }
            return callback();
        };
        const checkDate = (rule, value, callback) => {
            console.log(value);
            console.log(
                moment(value).diff(
                    moment(new Date().toISOString().slice(0, 10)),
                    "days"
                )
            );
            if (
                moment(value).diff(
                    moment(new Date().toISOString().slice(0, 10)),
                    "days"
                ) > 0
            ) {
                return callback(
                    new Error("No se puede superar la fecha de hoy")
                );
            }
            return callback();
        };
        // console.log(this.places);
        return {
            waiting: false,
            showLocal: false,
            placeDetail: {},
            ruleForm: {
                complaint_id: this.$props.complaint_id,
                description: this.$props.oldData.description
                    ? this.$props.oldData.description
                    : "",
                place: this.$props.oldData.place
                    ? this.$props.oldData.place
                    : "",
                local: this.$props.oldData.local
                    ? this.$props.oldData.local
                    : "",
                behavior: this.$props.oldData.behavior
                    ? this.$props.oldData.behavior
                    : "",
                date_of_incident: this.$props.oldData.date_of_incident
                    ? this.$props.oldData.date_of_incident
                    : "",
                accomplices_list: this.$props.oldData.accomplices_list
                    ? this.$props.oldData.accomplices_list
                    : "",
                people_involved: this.$props.oldData.people_involved
                    ? this.$props.oldData.people_involved
                    : "",
                reported_person: this.$props.oldData.reported_person
                    ? this.$props.oldData.reported_person
                    : "",
                have_accomplice: this.$props.oldData.have_accomplice
                    ? this.$props.oldData.have_accomplice
                    : "0",
                have_situation_reported: this.$props.oldData
                    .have_situation_reported
                    ? this.$props.oldData.have_situation_reported
                    : "0",
                have_witnesses: this.$props.oldData.have_witnesses
                    ? this.$props.oldData.have_witnesses
                    : "0",
                witnesses: this.$props.oldData.witnesses
                    ? this.$props.oldData.witnesses
                    : "",
                times: this.$props.oldData.times
                    ? this.$props.oldData.times
                    : "",
            },
            rules: {
                description: [
                    {
                        required: true,
                        message: "Por favor describa la situación",
                        trigger: "blur",
                    },
                    {
                        min: 10,
                        message: "Por favor informar más detalles del suceso",
                        trigger: "blur",
                    },
                ],
                place: [
                    {
                        required: true,
                        message: "Por favor indicar el lugar de suceso",
                        trigger: "change",
                    },
                ],
                local: [{ validator: checkLocal, trigger: ["change", "blur"] }],
                behavior: [
                    {
                        required: true,
                        message:
                            "Por favor indicar cómo identificó la situación",
                        trigger: "change",
                    },
                ],
                times: [
                    {
                        required: true,
                        message: "Por favor indicar si esto ha sucedido antes",
                        trigger: "change",
                    },
                ],
                date_of_incident: [
                    {
                        required: true,
                        message: "Por favor indicar una fecha aproximada",
                        trigger: "change",
                    },
                    { validator: checkDate, trigger: ["change", "blur"] },
                ],
                people_involved: [
                    {
                        required: true,
                        message: "Por favor indicar las personas involucradas",
                        trigger: "blur",
                    },
                    {
                        min: 10,
                        max: 200,
                        message: "Por favor informar más detalles del suceso",
                        trigger: "blur",
                    },
                ],
                accomplices_list: [
                    { validator: checkText, trigger: ["change", "blur"] },
                    {
                        min: 10,
                        max: 200,
                        message:
                            "Por favor informar más detalles de la sugerencia",
                        trigger: "blur",
                    },
                ],
                reported_person: [
                    { validator: checkText, trigger: ["change", "blur"] },
                    {
                        min: 10,
                        max: 200,
                        message: "Por favor indicar más detalles",
                        trigger: "blur",
                    },
                ],
                witnesses: [
                    { validator: checkText, trigger: ["change", "blur"] },
                    {
                        min: 10,
                        max: 200,
                        message: "Por favor indicar más detalles",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid, rules) => {
                if (valid) {
                    this.waiting = true;
                    const url = `${window.location.origin}/complaint/postdata2b`;
                    axios
                        .post(url, {
                            ...this.ruleForm,
                            complaint: this.complaint,
                        })
                        .then((res) => {
                            this.waiting = false;
                            if (res.data.success) {
                                this.$props.setId(
                                    res.data.id,
                                    res.data.complaint
                                );
                                this.$props.setStepData(this.ruleForm);
                                this.$props.next(3);
                            }
                        })
                        .catch((e) => {
                            this.waiting = false;
                        });
                } else {
                    this.goToError(rules);
                }
            });
        },
        validatePlaceCondition(index) {
            const newPlace = this.places.filter((row) => row.id === index)[0];
            this.placeDetail = newPlace;
            if (newPlace.have_list) {
                console.log(this.locals, this.oldData);
                this.showLocal = true;
                this.placeDetail.locals = this.locals.filter(
                    (row) => row.country_id === this.complaint.country_id
                );
                this.placeDetail.locals.sort(function (a, b) {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                });
            } else {
                this.showLocal = false;
                this.ruleForm.local = "";
            }
        },
    },
    mounted() {
        console.log(this.$props);
    },
};
</script>
