<template>
    <div class="bg-light mt-3 px-md-4 px-3 py-2">
        <div>
            <div id="form_output"></div>

            <el-collapse
                class="w-100 border-bottom-0 mb-3"
                v-model="activeNames"
            >
                <el-collapse-item name="1" class="div-filters">
                    <template slot="title"
                        ><h5 class="pl-3">
                            <i class="el-icon-plus mr-2" />Nuevo usuario
                        </h5>
                    </template>

                    <el-form
                        id="local_form"
                        ref="ruleForm"
                        status-icon
                        :rules="rules"
                        :model="user"
                    >
                        <div
                            class="row w-100 mx-0 px-1 mb-4 d-flex justify-content-between align-items-end"
                        >
                            <el-form-item
                                id="name"
                                prop="name"
                                class="col-md-4 m-0"
                            >
                                <label>Nombre completo</label>
                                <el-input
                                    type="text"
                                    v-model="user.name"
                                    placeholder="Ingrese el nombre del usuario"
                            /></el-form-item>
                            <el-form-item
                                id="email"
                                prop="email"
                                class="col-md-4 m-0"
                            >
                                <label>Email</label>
                                <el-input
                                    type="text"
                                    v-model="user.email"
                                    placeholder="Ingrese el correo del usuario"
                                />
                            </el-form-item>
                            <el-form-item
                                id="countries"
                                prop="countries"
                                class="col-md-4 m-0"
                            >
                                <label>Entidad</label>
                                <el-select
                                    v-model="user.countries"
                                    class="w-100"
                                    multiple
                                    filterable
                                    @change="
                                        (value) => {
                                            selectAll(value, 'countries');
                                        }
                                    "
                                >
                                    <el-option
                                        label="Todos"
                                        value="all"
                                    ></el-option>
                                    <el-option
                                        v-for="country in countries"
                                        :key="country.id"
                                        :label="country.name"
                                        :value="country.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                class="col-md-4 m-0"
                                id="complaints_states"
                                prop="complaints_states"
                            >
                                <label class="">Estado de denuncia</label>
                                <el-select
                                    v-model="user.complaints_states"
                                    class="w-100"
                                    multiple
                                    filterable
                                    @change="
                                        (value) => {
                                            selectAll(
                                                value,
                                                'complaints_states'
                                            );
                                        }
                                    "
                                >
                                    <el-option
                                        label="Todos"
                                        value="all"
                                    ></el-option>
                                    <el-option
                                        v-for="complaint_state in complaints_states"
                                        :key="complaint_state.id"
                                        :label="complaint_state.name"
                                        :value="complaint_state.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                class="col-md-8 m-0"
                                id="complaints_types"
                                prop="complaints_types"
                            >
                                <label class="w-100">Tipo de denuncia</label>
                                <el-select
                                    clearable
                                    v-model="user.complaints_types"
                                    class="w-100"
                                    multiple
                                    filterable
                                    @change="
                                        (value) => {
                                            selectAll(
                                                value,
                                                'complaints_types'
                                            );
                                        }
                                    "
                                >
                                    <el-option
                                        label="Todos"
                                        value="all"
                                    ></el-option>
                                    <el-option
                                        v-for="complaint_type in complaints_types"
                                        :key="complaint_type.id"
                                        :label="complaint_type.type"
                                        :value="complaint_type.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <div
                            class="row w-100 mx-0 px-1 mb-4 d-flex justify-content-between align-items-end"
                        >
                            <el-form-item class="col-md-4 m-0">
                                <el-checkbox v-model="user.isInvestigator"
                                    >Esta persona es
                                    <strong>investigador</strong></el-checkbox
                                >
                            </el-form-item>
                            <el-form-item class="col-md-4 m-0">
                                <el-checkbox v-model="user.isAssigner"
                                    >Esta persona es
                                    <strong>asignador</strong></el-checkbox
                                >
                            </el-form-item>
                            <el-form-item class="col-md-4 m-0">
                                <el-checkbox v-model="user.isEditor"
                                    >Esta persona es
                                    <strong>editor</strong></el-checkbox
                                >
                            </el-form-item>
                            <el-form-item
                                id="receive_mail"
                                prop="receive_mail"
                                class="col-md-4 m-0"
                            >
                                <el-checkbox
                                    label="Esta persona recibe correo"
                                    v-model="user.receive_mail"
                                ></el-checkbox>
                                <!-- <el-checkbox v-model="user.receive_mail" >Si.</el-checkbox> -->
                            </el-form-item>
                            <div
                                class="col-md-12 pt-3 text-right d-flex justify-content-between"
                            >
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="limpiar"
                                    placement="top"
                                >
                                    <el-button
                                        class="btn-big-icon h-100"
                                        plain
                                        @click="clearForm"
                                        icon="big-icon el-icon-brush"
                                    />
                                </el-tooltip>
                                <button
                                    @click.prevent="submitForm('ruleForm')"
                                    :disabled="waiting"
                                    class="btn btn-info text-white mb-2"
                                >
                                    {{ isEdition ? "Actualizar" : "Guardar" }}
                                </button>
                            </div>
                        </div>
                    </el-form>
                </el-collapse-item>
            </el-collapse>
        </div>
        <!-- -------------------------- -->
        <div class="row w-100 mx-0">
            <el-table
                :data="users"
                style="width: 100%; height: 100%"
                max-height="500"
                v-loading="waiting"
                :cell-class-name="classAsign"
            >
                <el-table-column
                    prop="name"
                    label="Nombre completo"
                    width="160"
                >
                </el-table-column>
                <el-table-column prop="email" label="Email" width="220">
                </el-table-column>
                <el-table-column
                    width="140"
                    label="Entidades"
                    :filters="countryFilters()"
                    :filter-method="filterCountries"
                >
                    <template slot-scope="scope">
                        <el-tag
                            v-for="country in scope.row.countries"
                            :key="
                                country.id +
                                '-country-' +
                                scope.$index +
                                '-' +
                                scope.row.id
                            "
                        >
                            {{ country.name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    width="250"
                    label="Tipo de denuncia"
                    :filters="complaintTypeFilters()"
                    :filter-method="filterComplaintTypes"
                >
                    <template slot-scope="scope">
                        <el-tag
                            v-for="complaint_type in scope.row.complaints_types"
                            :key="
                                complaint_type.id +
                                '-complaint_types-' +
                                scope.$index +
                                '-' +
                                scope.row.id
                            "
                        >
                            {{ complaint_type.type }}
                        </el-tag>
                    </template> </el-table-column
                ><el-table-column width="120" label="Estado de denuncia">
                    <template slot-scope="scope">
                        <el-tag
                            v-for="complaints_state in scope.row
                                .complaints_states"
                            :key="
                                complaints_state.id +
                                '-complaints_states-' +
                                scope.$index +
                                '-' +
                                scope.row.id
                            "
                        >
                            {{ complaints_state.name }}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column
                    class-name="item-center"
                    min-width="100"
                    label="Acciones"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <div class="row d-flex justify-content-center">
                            <div class="col-md-4 m-0 mb-2 p-0">
                                <el-button
                                    @click="editUser(scope.$index)"
                                    :type="
                                        isEdition && scope.$index == index
                                            ? 'warning'
                                            : 'primary'
                                    "
                                    :icon="
                                        isEdition && scope.$index == index
                                            ? 'el-icon-close'
                                            : 'el-icon-edit'
                                    "
                                    size="small"
                                />
                            </div>
                            <div class="col-md-4 m-0 p-0">
                                <el-button
                                    @click="showConfirmModal(scope.$index)"
                                    type="danger"
                                    icon="el-icon-delete"
                                    size="small"
                                ></el-button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        "complaints_states",
        "complaints_types",
        "countries",
        "userId",
        "users",
    ],
    data() {
        let checkMailFormat = (rule, value, callback) => {
            if (!this.isEmailValid(value)) {
                return callback(new Error("Formato de email incorrecto"));
            }
            return callback();
        };
        let checkComplaintsTypes = (rule, value, callback) => {
            if (this.user.receive_mail === "0" && !this.truthty(value)) {
                return callback(
                    new Error("Debe agregar al menos 1 tipo de denuncia")
                );
            }
            return callback();
        };
        return {
            activeNames: [],
            user: {
                name: "",
                email: undefined,
                countries: [],
                complaints_types: [],
                complaints_states: [],
                receive_mail: 0,
                isInvestigator: 0,
                isAssigner: 0,
                isEditor: 0,
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "Debe escribir el nombre del usuario",
                        trigger: ["change", "blur"],
                    },
                ],
                email: [
                    {
                        required: true,
                        message: "Debe escribir el email del usuario",
                        trigger: ["change", "blur"],
                    },
                    { validator: checkMailFormat, trigger: "blur" },
                ],
                countries: [
                    {
                        required: true,
                        message: "Debe agregar al menos 1 entidad",
                        // trigger: ["change", "blur"],
                    },
                ],
                complaints_types: [
                    {
                        required: true,
                        message: "Debe agregar al menos 1 tipo de denuncia",
                        // trigger: ["change", "blur"],
                    },
                ],
                complaints_states: [
                    {
                        required: true,
                        message: "Debe agregar al menos 1 estado de denuncia",
                        // trigger: ["change", "blur"],
                    },
                ],
            },
            editedUserId: undefined,
            isEdition: false,
            editedUserIndex: undefined,
            isDelete: false,
            waiting: false,
            index: null,
        };
    },
    methods: {
        classAsign(element) {
            const { row, column, rowIndex, columnIndex } = element;
            // console.log(column.label);
            if (
                column.label == "Tipo de denuncia" ||
                column.label == "Entidades"
            ) {
                return "cell-complaint-type";
            }
        },
        selectAll(values, type) {
            let addAll = values.includes("all");
            if (addAll) {
                this.user[type] = this.$props[type].map((x) => x.id);
            }
        },
        submitForm(formName) {
            // console.log(formName);
            this.$refs[formName].validate((valid, rules) => {
                console.log(valid, rules);
                if (valid) {
                    // console.log("API");
                    this.submit();
                } else {
                    this.goToError(rules);
                }
            });
        },
        countryFilters() {
            const filters = this.countries.map((country) => {
                return {
                    text: country.name,
                    value: country.id,
                };
            });
            return filters;
        },
        filterCountries(value, row) {
            return row.countries.find((country) => country.id == value);
        },
        complaintTypeFilters() {
            const filters = this.complaints_types.map((complaint_type) => {
                return {
                    text: complaint_type.type,
                    value: complaint_type.id,
                };
            });
            return filters;
        },
        filterComplaintTypes(value, row) {
            return row.complaints_types.find(
                (complaint_type) => complaint_type.id == value
            );
        },
        clearForm() {
            this.user = {
                name: "",
                email: undefined,
                countries: [],
                complaints_types: [],
                complaints_states: [],
                receive_mail: false,
                isInvestigator: false,
                isAssigner: false,
                isEditor: false,
            };
            this.$refs["ruleForm"].resetFields();
        },
        clearEditData() {
            this.isEdition = false;
            this.editedUserIndex = undefined;
            this.editedUserId = undefined;
            this.isDelete = false;
        },
        editUser(userIndex) {
            if (this.index == userIndex) {
                this.index = null;
                this.clearForm();
                this.clearEditData();
                return;
            }
            this.index = userIndex;
            const user = this.users[userIndex];
            // console.log(user);
            const {
                id,
                name,
                email,
                receive_mail,
                countries,
                complaints_types,
                complaints_states,
                isAssigner,
                isInvestigator,
                isEditor,
            } = user;
            const userCountries = countries.map((country) => country.id);
            const userComplaintsTypes = complaints_types.map(
                (complaint_type) => complaint_type.id
            );
            const userStates = complaints_states.map((state) => state.id);

            // console.log(userCountries, userComplaintsTypes);
            this.isEdition = true;
            this.editedUserId = id;
            this.editedUserIndex = userIndex;
            this.user = {
                name,
                email,
                receive_mail: receive_mail == 1,
                countries: userCountries,
                isInvestigator,
                isAssigner,
                isEditor,
                complaints_types: userComplaintsTypes,
                complaints_states: userStates,
            };
        },
        showConfirmModal(userIndex) {
            this.$confirm(
                "¿Está seguro/a que desea eliminar el usuario? Esta acción es irreversible.",
                "Atención",
                {
                    confirmButtonText: "Si, borrar",
                    cancelButtonText: "Cancelar",
                    type: "warning",
                }
            ).then(() => {
                const user = this.users[userIndex];
                const { id } = user;
                this.isDelete = true;
                this.editedUserId = id;
                this.editedUserIndex = userIndex;
                this.submit();
            });
        },
        submit() {
            // console.log(this.user);
            if (this.isEdition) {
                this.submitEdit();
            } else if (this.isDelete) {
                this.submitDelete();
            } else {
                this.submitCreate();
            }
            this.index = null;
        },
        submitCreate() {
            const url = `${window.location.origin}/users/create`;
            this.waiting = true;
            axios
                .post(url, this.user)
                .then((res) => {
                    this.waiting = false;
                    this.users.push(res.data.newUser);
                    this.clearForm();
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.waiting = false;
                    this.$message.error(
                        "Ha ocurrido un error al crear el usuario"
                    );
                });
        },
        submitEdit() {
            const {
                user,
                user: { countries, complaints_types },
                userId,
            } = this;
            let userEdited = this.copy(user);
            // userEdited.countries = {};
            // countries.forEach(country => {
            //     userEdited.countries[country] = { updated_usr: userId };
            // });
            // userEdited.complaints_types = {};
            // complaints_types.forEach(complaint_type => {
            //     userEdited.complaints_types[complaint_type] = complaint_type;
            // });
            this.waiting = true;
            const url = `${window.location.origin}/users/update/${this.editedUserId}`;
            axios
                .put(url, userEdited)
                .then((res) => {
                    this.waiting = false;
                    const aux = this.users.map((row) => {
                        if (row.id == this.editedUserId) {
                            return res.data.user;
                        } else return row;
                    });
                    this.users = this.copy(aux);
                    this.clearForm();
                    this.clearEditData();
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.waiting = false;

                    this.$message.error(
                        "Ha ocurrido un error al editar el usuario"
                    );
                });
        },
        submitDelete() {
            this.waiting = true;
            const url = `${window.location.origin}/users/${this.editedUserId}`;
            axios
                .delete(url)
                .then((res) => {
                    this.waiting = false;
                    this.users.splice(this.editedUserIndex, 1);
                    // this.clearForm();
                    this.clearEditData();
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.waiting = false;
                    console.error(error);
                    this.$message.error(
                        "Ha ocurrido un error al eliminar el usuario"
                    );
                });
        },
    },
    mounted() {
        if (this.checkMobile() == false) {
            this.activeNames = ["1"];
        }
    },
};
</script>
