var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-light mt-3 px-md-4 px-3 py-2"},[_c('div',[_vm._m(0),_vm._v(" "),_c('div',{attrs:{"id":"form_output"}}),_vm._v(" "),_c('el-form',{ref:"ruleForm",staticClass:"col w-100 p-0",attrs:{"id":"local_form","rules":_vm.rules,"model":_vm.place,"status-icon":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm('ruleForm')}}},[_c('div',{staticClass:"row w-100 d-flex justify-content-between mx-0"},[_c('el-form-item',{staticClass:"col pl-0",attrs:{"prop":"name","id":"name"}},[_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Ingrese el nombre de la comuna"},model:{value:(_vm.place.name),callback:function ($$v) {_vm.$set(_vm.place, "name", $$v)},expression:"place.name"}})],1),_vm._v(" "),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-info text-white",attrs:{"size":"small"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm('ruleForm')}}},[_vm._v("\n                        "+_vm._s(_vm.isEdition ? "Actualizar" : "Guardar")+"\n                    ")])])],1)])],1),_vm._v(" "),_c('div',{staticClass:"row w-100 mx-0"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.waiting),expression:"waiting"}],staticStyle:{"width":"100%","height":"100%"},attrs:{"data":_vm.places,"max-height":"350","default-sort":{ prop: 'id', order: 'ascending' }}},[_c('el-table-column',{attrs:{"prop":"id","label":"Cód","width":"90","sortable":""}}),_vm._v(" "),_c('el-table-column',{attrs:{"sortable":"","prop":"name","label":"Comuna","min-width":"250"}}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Estado","min-width":"200","filters":[
                    { text: 'Activo', value: 1 },
                    { text: 'Inactivo', value: 0 },
                ],"filter-method":_vm.filterState},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-switch',{staticClass:"align-items-center switch-compact switch",attrs:{"value":scope.row.active == 1,"active-text":"Activo","inactive-text":"Inactivo"},on:{"change":function($event){return _vm.editPlaceState(scope.$index)}}})]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"class-name":"item-center","fixed":"right","label":"Acciones","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"small","type":_vm.isEdition && scope.$index == _vm.index
                                ? 'warning'
                                : 'primary',"icon":_vm.isEdition && scope.$index == _vm.index
                                ? 'el-icon-close'
                                : 'el-icon-edit'},on:{"click":function($event){return _vm.editPlace(scope.$index)}}})]}}])})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h5',[_vm._v("Nueva comuna")])])
}]

export { render, staticRenderFns }