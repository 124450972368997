<template>
    <el-card class="w-100 div-complaint-detail">
        <div class="row px-3">
            <div class="col-md-2 p-0">
                <div class="row justify-content-between">
                    <div class="col-md-10 col-7">
                        <label class="h5 mb-0 mr-3"
                            >Código {{ complaint.id }}
                            <i
                                v-if="
                                    truthty(complaint.currentInvestigator) &&
                                    (auth.isSuper ||
                                        auth.isAssigner ||
                                        auth.id ==
                                            complaint.currentInvestigator)
                                "
                                class="el-icon-s-flag"
                                :style="`color:${complaint.case_assignments[0].color}`"
                            ></i>
                        </label>
                        <br />
                        <small class="mb-1"
                            >Línea ética
                            <strong>{{
                                complaint.complete ? "completa" : "rápida"
                            }}</strong></small
                        >
                    </div>
                    <div
                        class="col-md-1 col-3 mr-3 p-0 d-md-none d-flex justify-content-end"
                    >
                        <el-tooltip content="Editar" placement="top">
                            <el-button
                                class="btn-big-icon d-flex align-items-end"
                                type="primary"
                                icon="big-icon el-icon-edit-outline"
                                @click="dialogVisible"
                            />
                        </el-tooltip>
                        <el-tooltip
                            content="Asignar"
                            placement="top"
                            v-if="auth.isSuper || auth.isAssigner"
                        >
                            <el-button
                                class="btn-big-icon d-flex align-items-end"
                                type="warning"
                                icon="big-icon el-icon-s-custom"
                                @click="dialogInvVisible"
                            />
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div
                class="col-md-8 px-0 d-flex flex-wrap align-items-center justify-content-between"
            >
                <!-- tag para si es denuncia o sugerencia  -->
                <el-tag
                    v-if="complaint.suggestion"
                    effect="dark"
                    class="ml-0 my-2"
                >
                    <i class="el-icon-warning-outline mr-1" />Sugerencia</el-tag
                >
                <!-- tag para si es denuncia o sugerencia  -->
                <!-- <el-tag v-if="complaint.suggestion" effect="dark" class=" rounded-circle"
          >
          <i class="el-icon-warning-outline" /></el-tag
        > -->

                <el-tag v-else effect="dark" class="ml-0 my-2"
                    ><i class="el-icon-warning-outline mr-1" />Denuncia</el-tag
                >
                <!-- tag para informar estado de denuncia  -->
                <el-tag class="tag-complaint-state ml-0 my-2" effect="dark"
                    ><i class="el-icon-picture-outline-round mr-1" />{{
                        complaint.complaintStateText
                    }}</el-tag
                >
                <!-- tag para informar país  -->
                <el-tag type="warning" effect="dark" class="ml-0 my-2"
                    ><i class="el-icon-location mr-1" />
                    {{ complaint.country.name }}</el-tag
                >
                <!-- tag para informar anonimato  -->
                <el-tag
                    v-if="!complaint.anonymous"
                    class="h5 m-0"
                    type="success"
                    effect="dark"
                >
                    <i class="el-icon-user mr-1" /> {{ complaint.contact_name }}
                </el-tag>
                <el-tag v-else type="danger" effect="dark" class="ml-0 my-2"
                    ><i class="el-icon-question mr-1" /> Anónimo</el-tag
                >
                <!-- tag para informar tipo de denuncia  -->
                <el-tag
                    v-if="!complaint.suggestion"
                    type="danger"
                    effect="plain"
                    class="ml-0 my-2"
                    ><i class="el-icon-collection-tag mr-1" />{{
                        complaint.complaintClasificationText
                    }}</el-tag
                >
                <!-- tag para informar cargo  -->
                <el-tag v-if="!complaint.anonymous" class="h5 ml-0 my-2"
                    ><i class="el-icon-s-custom mr-1" />{{
                        complaint.characterText
                    }}</el-tag
                >
                <el-tag effect="plain" class="ml-0 my-2"
                    ><i class="el-icon-date mr-1" />
                    {{ complaint.created_at }}</el-tag
                >
                <el-tag effect="plain" type="info" class="ml-0 my-2"
                    ><i class="el-icon-date mr-1" />
                    {{ complaint.currentTime }}</el-tag
                >
            </div>
            <div class="col-md-2 pl-0 pt-2 d-flex justify-content-end">
                <el-tooltip
                    content="Editar"
                    placement="top"
                    v-if="
                        auth.isSuper ||
                        auth.isAssigner ||
                        auth.isEditor ||
                        auth.isInvestigator
                    "
                >
                    <el-button
                        class="btn-big-icon d-md-block d-none"
                        type="primary"
                        size="mini"
                        icon="big-icon el-icon-edit-outline"
                        @click="dialogVisible"
                    />
                </el-tooltip>
                <el-tooltip
                    content="Asignar"
                    placement="top"
                    v-if="auth.isSuper || auth.isAssigner"
                >
                    <el-button
                        class="btn-big-icon d-md-block d-none"
                        type="warning"
                        size="mini"
                        icon="big-icon el-icon-s-custom"
                        @click="dialogInvVisible"
                    />
                </el-tooltip>
                <el-tooltip
                    class="item"
                    effect="dark"
                    content="Generar Reporte"
                    placement="top"
                >
                    <el-button
                        class="btn-big-icon h-100"
                        type="success"
                        plain
                        @click="downloadComplaint(complaint.id)"
                        icon="big-icon el-icon-download"
                    />
                </el-tooltip>
            </div>

            <div class="col-md-12 mt-3">
                <el-collapse v-model="compact">
                    <el-collapse-item name="1">
                        <template slot="title"
                            ><h6 class="mb-0 mr-3">Más detalles</h6></template
                        >
                        <div class="row">
                            <div
                                v-if="!complaint.anonymous"
                                class="col-md-3 col-sm-6"
                            >
                                <label class="m-0"><b>Denunciante</b></label>
                                <p>{{ complaint.contact_name }}</p>
                                <label class="m-0"><b>Teléfono</b></label>
                                <p>{{ complaint.contact_phone }}</p>
                                <label class="m-0"><b>Email</b></label>
                                <p>{{ complaint.contact_email }}</p>
                                <label class="m-0"><b>Rut</b></label>
                                <p class="mb-1">{{ complaint.document_id }}</p>
                                <p>
                                    {{ complaint.affectsItDirectlyText }} me
                                    afecta directamente
                                </p>
                            </div>
                            <div
                                v-else-if="truthty(complaint.contact_email)"
                                class="col-md-3"
                            >
                                <label class="m-0"><b>Email</b></label>
                                <p>{{ complaint.contact_email }}</p>
                                <h5>
                                    No hay información de contacto adicional
                                </h5>
                            </div>
                            <div v-else class="col-md-3">
                                <h5>No hay información de contacto</h5>
                            </div>
                            <div class="col-md-3 col-sm-6">
                                <div v-if="!complaint.suggestion">
                                    <label class="m-0"><b>Comuna</b></label>
                                    <p>{{ complaint.placeText }}</p>
                                    <label class="m-0"><b>Local</b></label>
                                    <p>{{ complaint.localText }}</p>
                                    <label class="m-0"
                                        ><b>Forma de detección</b></label
                                    >
                                    <p>{{ complaint.behaviorText }}</p>
                                    <label class="m-0"
                                        ><b>Fecha aprox. de suceso</b></label
                                    >
                                    <p class="mb-1">
                                        {{ complaint.approximate_date }}
                                    </p>
                                    <!-- ------------- -->
                                    <!-- <p>{{ complaint.currentTime }}</p> -->
                                    <!-- -------------------- -->
                                    <p>{{ complaint.timeText }}</p>
                                </div>
                                <div v-if="complaint.suggestion">
                                    <label class="m-0"
                                        ><b>Descripción</b></label
                                    >
                                    <p>
                                        {{
                                            complaint.description
                                                ? complaint.description
                                                : "No se ha dado una descripción"
                                        }}
                                    </p>
                                </div>
                            </div>
                            <template v-if="!complaint.suggestion">
                                <div class="col-md-3 col-sm-6">
                                    <label class="m-0"
                                        ><b>Descripción</b></label
                                    >
                                    <p>
                                        {{
                                            complaint.description
                                                ? complaint.description
                                                : "No se ha dado una descripción"
                                        }}
                                    </p>
                                </div>
                                <div class="col-md-3">
                                    <label class="m-0"
                                        ><b>Personas involucradas</b></label
                                    >
                                    <p>
                                        {{
                                            complaint.people_involved.length > 0
                                                ? complaint.people_involved
                                                : "No se reconocen las personas involucradas"
                                        }}
                                    </p>
                                    <label class="m-0"><b>Cómplices</b></label>
                                    <p>
                                        {{
                                            complaint.accomplice.length > 0
                                                ? complaint.accomplice
                                                : "No se reconocen cómplices"
                                        }}
                                    </p>
                                    <label class="m-0"><b>Testigos</b></label>
                                    <p class="mb-1">
                                        {{
                                            complaint.witnesses &&
                                            complaint.witnesses_names.length > 0
                                                ? complaint.witnesses_names
                                                : "No se reconocen testigos"
                                        }}
                                    </p>
                                    <p>
                                        {{
                                            complaint.reported &&
                                            complaint.reported_names.length > 0
                                                ? "Se ha reportado la situación a " +
                                                  complaint.reported_names
                                                : "No se ha reportado la incidencia"
                                        }}
                                    </p>
                                </div>
                            </template>
                            <template v-if="complaint.suggestion">
                                <div class="col-sm-6 col-md-3">
                                    <label class="m-0"><b>Sugerencia</b></label>
                                    <p>
                                        {{
                                            complaint.suggestion_text.length > 0
                                                ? complaint.suggestion_text
                                                : "No se ha indicado una sugerencia"
                                        }}
                                    </p>
                                    <label class="m-0"><b>Impacto</b></label>
                                    <p>
                                        {{
                                            complaint.impact.length > 0
                                                ? complaint.impact
                                                : "No se ha indicado un impacto"
                                        }}
                                    </p>
                                </div>
                                <div class="col-sm-6 col-md-3">
                                    <label class="m-0"
                                        ><b>Resultado esperado</b></label
                                    >
                                    <p>
                                        {{
                                            complaint.expected_result.length > 0
                                                ? complaint.expected_result
                                                : "No se ha indicado un resultado esperado"
                                        }}
                                    </p>
                                </div>
                            </template>
                        </div>
                        <el-divider class="mx-0"></el-divider>
                        <div class="row">
                            <div class="col-sm-4">
                                <label class="m-0"
                                    ><b>Información extra</b></label
                                >
                                <p>
                                    {{
                                        complaint.how_know
                                            ? complaint.how_know
                                            : "No se ha entregado información extra"
                                    }}
                                </p>
                            </div>
                            <div class="col-sm-3">
                                <p class="m-0"><b>Archivos</b></p>
                                <template
                                    v-if="
                                        [
                                            ...complaint.fileList,
                                            ...log_with_files,
                                        ].length > 0
                                    "
                                >
                                    <a
                                        v-for="(file, index) in [
                                            ...complaint.fileList,
                                            ...log_with_files,
                                        ]"
                                        :key="index"
                                        :href="file"
                                        target="_blank"
                                        >Archivo {{ index + 1 }}</a
                                    >
                                </template>

                                <p v-else>No se han entregado archivos</p>
                            </div>
                            <div class="col-sm-5">
                                <p class="m-0"><b>Actualizaciones</b></p>
                                <div class="div-state-logs">
                                    <p
                                        v-for="(
                                            log, index
                                        ) in complaint.state_logs"
                                        :key="index"
                                    >
                                        <small
                                            >{{
                                                moment(log.created_at).format(
                                                    "YYYY-MM-DD hh:mm:ss"
                                                )
                                            }}
                                            {{
                                                log.user && log.user.email
                                            }}</small
                                        ><br />
                                        <i>{{ log.complaint_state.name }}</i>
                                        <br />
                                        <i v-if="log.fileUrl">
                                            <a
                                                :key="index"
                                                :href="log.fileUrl"
                                                target="_blank"
                                                >Archivo
                                                {{
                                                    moment(
                                                        log.created_at
                                                    ).format(
                                                        "YYYY-MM-DD hh:mm:ss"
                                                    )
                                                }}
                                            </a>
                                        </i>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <card-body-asignament
                            v-for="case_assignament in complaint.case_assignments"
                            :key="case_assignament.id"
                            :users="users"
                            :case_assignment_default="case_assignament"
                            :auth="auth"
                            :getComplaints="getComplaints"
                            :activities="activities"
                        />
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </el-card>
</template>

<script>
const colors = { 33.3: "#67C23A", 66.6: "#E6A23C", 100: "#F56C6C" };

export default {
    props: [
        "complaint",
        "dialogVisible",
        "comprimido",
        "dialogInvVisible",
        "auth",
        "users",
        "getComplaints",
        "activities",
    ],
    data() {
        return {
            log_with_files: [],
            compact: this.$props.comprimido ? ["1"] : [],
            customColors: [
                { color: colors[33.3], percentage: 33.3 },
                { color: colors[66.6], percentage: 66.6 },
                { color: colors[100], percentage: 100 },
            ],
        };
    },
    methods: {
        backgroundColor() {
            if (this.truthty(this.$refs.progress)) {
                return this.$refs.progress.getCurrentColor();
            } else {
                return "";
            }
        },
        complaintName(state_logs) {
            if (state_logs[state_logs.length - 1]) {
                return state_logs[state_logs.length - 1].complaint_state.name;
            }
            return "Sin información";
        },
        mounted() {
            const { state_logs } = this.complaint;
            let filter = state_logs.filter((x) => this.truthty(x.fileUrl));
            this.log_with_files = filter.map((x) => x.fileUrl);
        },
        async downloadComplaint(id) {
            try {
                const response = await axios({
                    url: `${window.location.origin}/pdf/complaint/${id}`,
                    method: "GET",
                    data: {},
                    responseType: "blob",
                });
                let fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                let fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "denuncia.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            } catch (error) {
                console.log(error.response);
            }
        },
    },
    watch: {
        comprimido: function (newComprimido, oldComprimido) {
            this.compact = newComprimido ? ["1"] : [];
        },
    },
    mounted() {
        // console.log(
        //   this.complaint.case_assignments,
        //   this.complaint.case_assignments.length
        // );
    },
};
</script>
